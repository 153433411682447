<template>
  <div ref="mapWrapper" class="map-wrapper">
    <div id="view" ref="view">
      <div id="map" ref="mapImage" class="map-base">
        <inline-svg
          :src="require(`../../assets/images/gallery1/map-2.svg`)"
          @loaded="svgLoaded($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, Power2 } from "gsap";
import panzoom from "panzoom";
export default {
  data() {
    return {
      viewInstance: null,
    };
  },
  mounted() {
    const vm = this;
    this.$store.commit("updateCurrentMap", 2);
  },
  methods: {
    svgLoaded(e) {
      const vm = this;
      const tl = new gsap.timeline();
      tl.set(this.$refs.mapImage, {
        scale: 1.5,
        opacity: 0,
      });
      tl.set("#steppe", {
        opacity: 0,
      });
      tl.set("#names > g", {
        opacity: 0,
      });
      tl.to(
        this.$refs.mapImage,
        {
          duration: 3,
          y: "15vh",
          opacity: 1,
          ease: Power2.easeInOut,
          onComplete: function() {
            vm.viewInstance = panzoom(vm.$refs.view, {
              zoomSpeed: 0.05,
              transformOrigin: { x: 0.5, y: 0.5 },
              minZoom: 0.8,
              maxZoom: 4,
            });
          },
        },
        "0"
      );
      tl.to(
        "#steppe",
        {
          duration: 3,
          opacity: 1,
          ease: Power2.easeOut,
        },
        "-=1.5"
      );
      tl.to(
        "#names",
        {
          duration: 2,
          opacity: 1,
          ease: Power2.easeOut,
          onComplete: function() {
            if (vm.$route.name == "Map-2") {
              vm.$store.commit("updateMapDialog", true);
            }
            const tlName = new gsap.timeline({ repeat: -1 });
            for (var i = 1; i < 5; i++) {
              tlName.to(`#names >g:nth-child(${i})`, {
                duration: 2,
                opacity: 1,
                ease: Power2.easeOut,
              });
              tlName.to(`#names >g:nth-child(${i})`, {
                delay: 1,
                duration: 2,
                opacity: 0,
                ease: Power2.easeIn,
              });
            }
          },
        },
        "0"
      );
    },
  },
};
</script>

<style></style>
